.wrapper__add{
    width: 100%;
    height: 100%;
    padding: 15;
    &--head{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content:flex-start;
        align-items: center;
        label{
            font-size: 25px;
            font-weight: 700;
            color: #5a5c69;
        }
    }
    &--content{
        width: 100%;
    }
    &--button{
        margin-top: 30px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}
.wrapper__content{
    &--item{
        display: flex;
        margin-top: 12px;
        margin-bottom: 12px;
        label{
            font-size: 20px;
            color: #333;
            width: 150px;
        }
        &--input{
            flex: 1;
            input{
                background-color: transparent;
                border: none;
                width: 100%;
                &:focus{
                    outline-width: 0;
                    border: none;
                }
            }
            .seperator{
                height: 1px;
                background-color: #eaeaea;
                width: 100%;
            }
        }
        &--tags{
            display: flex;
            flex: 1;
            align-items: center;
            .tag__item{
                margin-right: 15px;
            }
        }
        &--add{
            display: flex;
            width: 50%;
            align-items: center;
            cursor: pointer;
            justify-content: flex-end;
            div{
                font-weight: 700;
                &:hover{
                    color: #1c3f94;
                    border-bottom: 1px solid #1c3f94;
                }
            }
        }
    }
}
label{
    margin-bottom: 0px;
}
.wrap__button{
    display: flex;
    button{
        width: 150px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        margin: 10px;
        &:nth-last-child(1){
            background-color: #1c3f94;
            &:hover{
                background-color: #efefef;
            }
        }
        &:nth-child(1){
            background-color: #efefef;
            &:hover{
                background-color: #1c3f94;
            }
        }
    }
}

.form-url{
    input{
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.content__topic{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 70px;
    width: 200px;
}

.url__file{
    word-break: break-word;
    white-space: normal;
}
.verticalTd{
    vertical-align: middle;
}
.form-group{
    .textareaContent{
        min-height: 450px;
    }
}
.addImage{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.sizeImage{
    height: 70px;
    object-fit: contain;
}
.sizeSvg{
    width: 24px;
    height: 24px;
}
.card-header{
    div{
        cursor: pointer;
    }
}
.menu{
    li{
        cursor: pointer;
        &:hover{
            color: #333;
        }
    }
}

.wrap-header{
    display: flex;
    align-items: center;
}
.search__input{
    display: flex;
    align-items: center;
    width: 90%;
    input{
        border: none;
        padding: 5px;
        width: 70%;
        margin-right: 10px;
    }
}
.subtags{
    display: flex;
    justify-content: space-between;
    width: 80%;
    padding: 15px;
}