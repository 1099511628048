

  .file{
    display: flex;
    .head__file{
        display: flex;
        justify-content: space-between;
        height: 50px;
        padding-right: 15px;
        h2{
            color: #333;
            font-size: 20px;
            font-weight: 700;
        }
        &--search{
            display: flex;
            align-items: center;
            .search{
                position: relative;
                margin-right: 15px;
                input{
                    padding-left: 5px;
                    min-width: 250px;
                    &:focus{
                        outline-width: 0;
                    }
                }
                &__btn{
                    position: absolute;
                    top: 2px;
                    right: 5px;
                }
            }
        }
    }
    .head__link{
        display: flex;
        padding-right: 15px;
       
        &--sub{
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
  }

  .classSvg{
      width: 24px;
      height: 24px;
      margin-right: 5px;
  }
  .flexDiv{
      display: flex;
      align-items: center;
      &__text{
          font-size: 20px;
          font-weight: 700;
          margin-left: 10px;
          margin-right: 10px;
      }
  }

.tdName{
    span{
        cursor: pointer;
        &:hover{
            border-bottom: 1px solid blueviolet;
            color: blueviolet;
        }
    }
}

.tdAction{
    svg{
        margin-left: 10px;
        margin-right: 10px;
        &:hover{
            color: #2196F3;
            cursor: pointer;
        }
        &:nth-child(1){
            margin-left: 0;
        }
    }
}
table{
    color: #333 !important;
    font-size: 14px;
}
.inputFolder{
    width: 100%;
    padding-left: 5px;
    &:focus{
        border: 2px solid #1A73E8;
        outline-width: 0;
    }
}
.btnClose{
    padding: 6px 12px;
    cursor: pointer;
}
.btnDelete{
    background-color: #d32f2f;
    padding: 6px 12px;
    color: #fff;
}
.eventClick{
    cursor: pointer;
    &:hover{ 
        border-bottom: 1px solid #333;
        color: #1A73E8;
    }
}